.AgTable {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  //   rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  .CustomLoading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 3;
    justify-content: center;
    background: white;
  }
  .TableActionContainer {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .RightContainer {
      display: flex;
      align-items: center;
      .ActionButton {
        padding: 0 12px;
      }
    }
  }
  .ag-theme-alpine {
    flex: 1;
  }
}

.ag-floating-bottom-viewport,
.ag-sticky-bottom-viewport {
  .ag-cell {
    font-weight: 700 !important;
    div {
      font-weight: 700 !important;
    }
  }
}

//ant-design modifiers
.ag-root-wrapper {
  background: white !important;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  border: 0 !important;
  color: #576694 !important;
  border: 1px solid #e1e4e9b0 !important;
}

.ag-column-drop {
  background-color: white !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.ag-header-cell {
  background-color: #f5f6f9 !important;
}
.ag-header {
  border-bottom: 1px solid #f0f0f0 !important;
}
.ag-center-cols-container {
  .ag-row {
    border-bottom: 1px solid #f0f0f0 !important;
    // height: 34px !important;
  }
}

.ag-row-odd {
  background-color: #f8f9fb !important;
}
.ag-cell-expandable {
  display: flex !important;
  align-items: center !important;
}

.ag-header-cell-text {
  color: var(--text-black-dark) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 38px !important;
  display: flex;
  align-items: center;
}
.ag-cell {
  color: #374762 !important;
  height: 100% !important;
  font-size: 11px !important;
  p,
  span,
  small {
    font-size: 11px !important;
  }
}

.ag-header-cell {
  height: 38px !important;
}
.ag-header-row {
  height: 38px !important;
}

.ag-column-drop-cell {
  background: var(--base-color-blue);
  color: white;
  .ag-icon {
    color: white !important;
  }
}
.ag-drag-handle {
  .ag-icon {
    color: white !important;
  }
}

.ag-column-panel {
  background-color: white;
}

.ag-theme-alpine .ag-cell-focus-with-checkbox .ag-selection-checkbox {
  background-color: var(
    --base-color-blue
  ) !important; /* Override the default blue color with green */
}

.ag-theme-alpine .ag-header-cell-moving {
  background-color: var(
    --base-color-blue
  ) !important; /* Override the default blue color with green */
}

.ag-theme-alpine .ag-header-cell-moving-left {
  border-right-color: var(
    --base-color-blue
  ) !important; /* Override the default blue color with green */
}

.ag-theme-alpine .ag-header-cell-moving-right {
  border-left-color: var(
    --base-color-blue
  ) !important; /* Override the default blue color with green */
}

.ag-theme-alpine,
.ag-theme-alpine-dark,
.ag-theme-alpine-auto-dark {
  --ag-alpine-active-color: var(--base-color-blue) !important;
}

.ag-input-field-input {
  border-radius: 20px !important;
}
.ag-icon-filter {
  color: #2575dc !important;
}

.ag-cell {
  font-size: 12px; /* Adjust the font size as needed */
  height: 100% !important;
  display: flex;
  align-items: center;
}

.ag-floating-filter {
  border-top: 1px solid #f0f0f0 !important;
}
.ag-side-bar {
  border-left: 1px solid #f0f0f0 !important;
}
.ag-paging-panel {
  border-top: 1px solid #f0f0f0 !important;
}

.ag-cell-focus {
  background-color: #b5dffc !important;
}

.ag-header-group-cell-with-group {
  background-color: #f5f6f9 !important;
}

.ag-header-group-text {
  color: var(--text-black-dark) !important;
}

.ag-icon-contracted {
  color: var(--text-black-dark) !important;
}

.ag-text-field-input {
  border-radius: 0 !important;
}

.ag-header-cell.ag-column-first {
  background-color: #f8f9fb !important;
}
.PDFViewerToolSidebar {
  width: 500px;
  height: 100%;

  .Container {
    width: 100%;
    height: 100%;
    display: flex;
    .PDFViewer {
      height: 100%;
      width: 100%;
    }
  }
}

.ag-sticky-bottom {
  .gridcell {
    font-weight: 600 !important;
  }
  .ag-cell {
    font-weight: 600 !important;
  }
}

.ag-sticky-top {
  .gridcell {
    font-weight: 600 !important;
  }
  .ag-cell {
    font-weight: 600 !important;
  }
}
